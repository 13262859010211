<script lang="ts">
  import { A } from '#js/Application';
  import type { SetupAndroidParams } from './Interfaces';
  import Button from '#components/Button/Button.svelte';
  import { SetupPayloadMode } from '#js/modules/Setup';
  import SetupOnboarding from '../SetupOnboarding/SetupOnboarding.svelte';

  export let params: SetupAndroidParams;

  let avatarId: string = params.payload.avatarId;

  async function setAvatarId() {
    await A.setup.setAvatarId(avatarId);
  }
</script>

{#if params.payload.mode === SetupPayloadMode.MOBILE_ANDROID_INSTAGRAM}
  <div class="h-full w-full bg-gray-400 p-4 text-black">
    <div class="bg-gray-200 m-0 p-4 text-center">INSTAGRAM ANDROID</div>
    <div class="bg-gray-200 mt-4 p-4">
      <div>
        To participate and win awesome gifts, you need to install EL app.
      </div>
      <div>It's easy and fast, we guide you step by step.</div>
      <div class="mt-4">
        First click the 3 dots in the top right corner, then select <strong
          >Open in Chrome</strong
        >.
      </div>
    </div>
  </div>
{/if}

{#if params.payload.mode === SetupPayloadMode.MOBILE_ANDROID_CHROME}
  <SetupOnboarding {params}>
    <div slot="howto">
      <div class="text-xl">ANDROID HOWTO</div>
      <div>
        Tap on 3 dots in the top right corner, then select <strong
          >Install app</strong
        >.
      </div>
    </div>
  </SetupOnboarding>
  <!-- <div class="bg-gray-200 m-0 p-4 text-center">ANDROID CHROME</div>
    <div class="bg-gray-200 mt-4 p-4">
      <div class="mt-4">Choose your username:</div>
      <div class="mt-4">
        <input
          type="text"
          name="avatarId"
          bind:value={avatarId}
          style="border: 1px solid black; padding: 2px; width: 100%; font-size: 20px; text-align:center"
        />
      </div>
      <div class="mt-4">
        <Button
          params={{
            page: params.page,
            text: 'OK',
            onClick: setAvatarId,
          }}
        />
      </div>
    </div> -->
{/if}

{#if params.payload.mode === SetupPayloadMode.MOBILE_ANDROID_CHROME_TO_PWA}
  <div class="h-full w-full bg-gray-400 p-4 text-black">
    <div class="bg-gray-200 m-0 p-4 text-center">ANDROID INSTALL PWA</div>
    <div class="bg-gray-200 mt-4 p-4">
      <div class="mt-4">
        Your username has been registered, one last step to install EL app.
      </div>
      <div class="mt-4">
        Click the 3 dots in the top right corner, then select <strong
          >Install app</strong
        >.
      </div>
      <div class="mt-4">
        Once the installation has completed, open the app like any other app on
        your phone.
      </div>
    </div>
  </div>
{/if}

{#if params.payload.mode === SetupPayloadMode.MOBILE_ANDROID_BROWSER}
  <div class="h-full w-full bg-gray-400 p-4 text-black">
    <div class="bg-gray-200 m-0 p-4 text-center">ANDROID NOT CHROME</div>
    <div class="bg-gray-200 mt-4 p-4">
      <!-- <div>
        To participate and win awesome gifts, you need to install EL app.
      </div>
      <div class="mt-4">
        First, open that link in the Chrome browser (click to copy):
      </div>
      <div class="mt-4 font-bold text-center">
        https://www.experiencesluxe.com/app
      </div> -->
      <div class="mt-4">You cannot use {A.client.client.getBrowser()}</div>
    </div>
  </div>
{/if}

{#if params.payload.mode === SetupPayloadMode.MOBILE_ANDROID_PWA}
  <div class="h-full w-full bg-gray-400 p-4 text-black">
    <div class="bg-gray-200 m-0 p-4 text-center">ANDROID PWA</div>
    <div class="bg-gray-200 mt-4 p-4">
      <div>Loading...</div>
    </div>
  </div>
{/if}
