/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/browser';
import { f7 } from 'framework7-svelte';
import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import type { KernelModules } from '@kumaly/kernel-types';
import { bootApp } from '#app/js/boot';
import { default as appDatas } from '#app/config/application.json';
import { default as skeletonDatas } from '#app/config/skeleton.json';

import type { id, Page } from '#js/Interfaces';
import { ApplicationState } from '#js/Interfaces';
import Database from '#js/modules/Database';
import Client from '#js/modules/Client';
import External from '#js/modules/External';
import Framework7 from '#js/modules/Framework7';
import { Icons, icons } from '#js/modules/Icons';
import Libraries from '#js/modules/Libraries';
import Locales from '#js/modules/Locales';
import Network from '#js/modules/Network';
import SFT from '#js/modules/SFT';
import Pages from '#js/modules/Pages';
import Setup from '#js/modules/Setup';
import Skeleton from '#js/modules/Skeleton';
import Tracking from '#js/modules/Tracking';
import Wallet from '#js/modules/Wallet';

import { GuiKernel } from '#js/kernel/GuiKernel';
import type Events from '#js/kernel/Events';
import Config from './Config';

export class Application {
  public id: id;

  public state = writable(ApplicationState.LOADING);

  public tab = writable('home');

  public kernel: GuiKernel;

  public database: Database;

  public client: Client;

  public external: External;

  public framework7: Framework7;

  public icons: Icons = icons;

  public libraries: Libraries;

  public locales: Locales;

  public network: Network;

  public pages: Pages;

  public sft: SFT;

  public skeleton: Skeleton;

  public setup: Setup;

  public tracking: Tracking;

  public wallet: Wallet;

  public constructor() {
    this.setContext();

    this.id = appDatas.id;
    this.kernel = new GuiKernel(this);
    this.database = new Database(this);
    this.client = new Client(this);
    this.external = new External(this);
    this.framework7 = new Framework7(this);
    this.libraries = new Libraries(this);
    this.locales = new Locales(this);
    this.network = new Network(this);
    this.pages = new Pages(this);
    this.sft = new SFT(this);
    this.skeleton = new Skeleton(skeletonDatas);
    this.setup = new Setup(this);
    this.tracking = new Tracking(this);
    this.wallet = new Wallet(this);
  }

  // *** SHORTCUTS

  public get d(): Database {
    return this.database;
  }

  public get e(): Events {
    return this.k.events;
  }

  public get k(): GuiKernel {
    return this.kernel;
  }

  public get km(): KernelModules {
    return this.kernel.modules;
  }

  public get p(): string {
    return Config.getPath();
  }

  public get timestamp(): number {
    return Math.ceil(Date.now() / 1000);
  }

  public get version(): string {
    return Config.getVersion();
  }

  public get w(): number {
    return this.client.getResolutionWidthMain();
  }

  public get bp(): string {
    if (this.client.getResolutionWidth() < 640) return 'xs';
    if (this.client.getResolutionWidth() < 768) return 'sm';
    if (this.client.getResolutionWidth() < 1024) return 'md';
    if (this.client.getResolutionWidth() < 1280) return 'lg';
    if (this.client.getResolutionWidth() < 1536) return 'xl';
    return '2xl';
  }

  public t(key: string): string {
    return this.locales.getText(key);
  }

  // **** STATES

  public async setState(state: ApplicationState): Promise<void> {
    console.log(`App State: ${state}`);
    if (state === ApplicationState.INIT) await this.setInitState();
    if (state === ApplicationState.SETUP) await this.setSetupState();
    if (state === ApplicationState.READY) await this.setReadyState();
  }

  private async setInitState(): Promise<void> {
    await this.k.loadPursesManager();
    await this.k.loadCdnModule();

    this.locales.loadLocales();
    this.locales.initFromBrowserSettings();
    this.state.set(ApplicationState.INIT);
    await bootApp();
  }

  private async setSetupState(): Promise<void> {
    this.state.set(ApplicationState.SETUP);

    if (await this.setup.process()) {
      console.log('SETUP DONE');
      this.setState(ApplicationState.READY);
    }
  }

  private async setReadyState(): Promise<void> {
    this.state.set(ApplicationState.READY);
    await this.d.preload();
    await this.kernel.initPurse();
  }

  // **** PAGE

  public hideToolbar(): void {
    if (!f7 || !f7.toolbar) return;
    f7.toolbar.hide('#toolbar');
  }

  public closePopup(id: string): void {
    if (!f7 || !f7.popup) return;
    f7.popup.close(`.popup-${id}`);
  }

  public openPopup(id: string): void {
    if (!f7 || !f7.popup) return;
    f7.popup.open(`.popup-${id}`);
    this.tracking.trackPopupFilter();
  }

  // **** TOOLS

  // Disable unused params warnings
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public void(params: any): void {}

  public initStringNonce(): Writable<string> {
    return writable('');
  }

  public initNumberNonce(): Writable<number> {
    return writable(0);
  }

  public reload(time: number) {
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, time);
  }

  public cloneObject(obj: object): object {
    return JSON.parse(JSON.stringify(obj)) as object;
  }

  public cloneObjectSurcharge(obj: object, surcharge: object): object {
    const json = this.cloneObject(obj);
    Object.keys(surcharge).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      json[key] = surcharge[key];
    });
    return json;
  }

  public async copyToClipboard(text: string): Promise<void> {
    await navigator.clipboard.writeText(text);
  }

  public setContext(): void {
    Sentry.setContext('kumaly', {
      chainId: Config.getChainId(),
      network: Config.getNetwork(),
      gateway: Config.getGatewayUrl(),
      avatarId: this.k && this.k.avatar ? this.k.avatar.id : null,
      addressId: this.k && this.k.purse ? this.k.purse.addressId : null,
    });

    if (this.k && this.k.wallet && this.k.avatar)
      Sentry.setUser({
        id: this.k.purse.addressId,
        username: this.k.avatar.id,
      });
  }

  public startPageTransaction(path: string): any {
    if (path === '/sidebar') return null;
    return Sentry.startTransaction({ name: path });
  }

  public finishPageTransaction(page: Page): void {
    if (!page.transaction) return;
    page.transaction.finish();
  }

  /*
  public fromVariationToRarity(variation: Variation): string {
    if (variation.hasTagId('looters:rarity_uncommon')) return 'uncommon';
    if (variation.hasTagId('looters:rarity_rare')) return 'rare';
    if (variation.hasTagId('looters:rarity_mythic')) return 'mythic';
    if (variation.hasTagId('looters:rarity_legendary')) return 'legendary';
    return 'common';
  }

  public async prepareUnity(
    collectionId: id,
    transactionHash: string,
    contract: Contract
  ): Promise<void> {
    const quantities = await this.sft.getVariationsCount(contract.id);
    const store = quantities.reverse().join('');

    this.e.unityPopupStore.set(store);
    this.e.unityPopupCollectionId.set(collectionId);
    this.e.unityPopupTransactionHash.set(transactionHash);
  }
  */
}

export const A = new Application();
A.setState(ApplicationState.INIT);

export * from './Interfaces';
export { type Writable } from 'svelte/store';
export { onMount } from 'svelte';
export { afterUpdate } from 'svelte';
export { f7 } from 'framework7-svelte';
